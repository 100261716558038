/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
// import axios from "./axios.js";
import routerGuard from "./router/routerGuard";
// import RouteViewComponent from "./router/routerView";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "main",
          meta: { url: "/", parent: "" },
          component: () => import("./views/pages/Main.vue"),
        },
        {
          path: "/home",
          name: "home",
          component: () => import("./views/pages/Main.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("./views/profile/profile.vue"),
        },
        {
          path: "/profile/edit",
          name: "profile-edit",
          component: () => import("./views/profile/edit.vue"),
        },
        {
          path: "/profile/change-password",
          name: "profile-change-password",
          component: () => import("./views/profile/change_password.vue"),
        },
        {
          path: "master",
          component: () => import("./views/master"),
          children: [
            {
              path: "route-plan",
              component: () => import("./views/master/index.vue"),
              children: [
                {
                  path: "",
                  name: "route-plan",
                  component: () =>
                    import("./views/master/route-plan/index.vue"),
                },
                {
                  path: "create",
                  name: "route-plan-create",
                  component: () =>
                    import("./views/master/route-plan/Create2.vue"),
                },
                {
                  path: ":id/edit",
                  name: "route-plan-edit",
                  component: () =>
                    import("./views/master/route-plan/Edit2.vue"),
                },
                {
                  path: ":id/view",
                  name: "route-plan-view",
                  component: () => import("./views/master/route-plan/View2.vue"),
                },
                {
                  path: "import",
                  name: "route-plan-import",
                  component: () =>
                    import("./views/master/route-plan/import/index.vue"),
                },
              ],
            },
            {
              path: "route-assignment",
              component: () => import("./views/master/index.vue"),
              children: [
                {
                  path: "",
                  name: "route-assignment",
                  component: () =>
                    import("./views/master/route-assignment/index.vue"),
                },
                {
                  path: "create",
                  name: "route-assignment-create",
                  component: () =>
                    import("./views/master/route-assignment/Form.vue"),
                },
                {
                  path: ":id/edit",
                  name: "route-assignment-edit",
                  component: () =>
                    import("./views/master/route-assignment/Form.vue"),
                },
                {
                  path: "import",
                  name: "route-assignment-import",
                  component: () =>
                    import("./views/master/route-assignment/import/index.vue"),
                },
              ],
            },
            {
              path: "visit-plan-generate",
              component: () => import("./views/master/index.vue"),
              children: [
                {
                  path: "",
                  name: "visit-plan-generate",
                  component: () =>
                    import("./views/master/visit-plan-generate/index.vue"),
                },
                {
                  path: ":id/edit",
                  name: "visit-plan-generate-edit",
                  component: () =>
                    import("./views/master/visit-plan-generate/Edit.vue"),
                },
              ],
            },
            {
              path: "customer-visit-plan",
              name: "customer-visit-plan",
              component: () =>
                import("./views/master/customer-visit-plan/index.vue"),
            },
            {
              path: "customer-visit-plan/:id/plan",
              name: "customer-visit-plan-plan",
              component: () =>
                import("./views/master/customer-visit-plan/plan/index.vue"),
            },
            {
              path: "customer-visit",
              name: "customer-visit",
              component: () =>
                import("./views/master/customer-visit/index.vue"),
            },
            {
              path: "flyer",
              name: "flyer",
              component: () => import("./views/master/flyer/index.vue"),
            },
            {
              path: "flyer-territory/create-update",
              name: "flyer-territory-create-update",
              component: () =>
                import("./views/master/flyer/flyer-territory/form.vue"),
            },
            {
              path: "flyer-territory/create",
              name: "flyer-territory-create",
              component: () =>
                import("./views/master/flyer/flyer-territory/form/create.vue"),
            },
            {
              path: "flyer-territory/:id/edit",
              name: "flyer-territory-edit",
              component: () =>
                import("./views/master/flyer/flyer-territory/form/edit.vue"),
            },
            {
              path: "news",
              name: "news",
              component: () => import("./views/master/news/index.vue"),
            },
            {
              path: "work-position",
              name: "work-position",
              component: () => import("./views/master/work-position"),
            },
            {
              path: "product-teams",
              component: () => import("./views/master"),
              children: [
                {
                  path: "",
                  name: "product-teams",
                  component: () =>
                    import("./views/master/product-teams/index.vue"),
                },
                {
                  path: "create",
                  name: "product-teams-create",
                  component: () =>
                    import("./views/master/product-teams/create.vue"),
                },
                {
                  path: ":id/edit",
                  name: "product-teams-edit",
                  component: () =>
                    import("./views/master/product-teams/edit.vue"),
                },
                {
                  path: ":id/view",
                  name: "product-teams-view",
                  component: () =>
                    import("./views/master/product-teams/view.vue"),
                },
                {
                  path: "import",
                  name: "product-teams-import",
                  component: () =>
                    import("./views/master/product-teams/import/index.vue"),
                },
              ],
            },
            {
              path: "product-must-haves",
              component: () => import("./views/master/index.vue"),
              children: [
                {
                  path: "",
                  name: "product-must-haves",
                  component: () =>
                    import("./views/master/product-must-haves/index.vue"),
                },
                {
                  path: "create",
                  name: "product-must-haves-create",
                  component: () =>
                    import("./views/master/product-must-haves/create.vue"),
                },
                {
                  path: ":territory_id/:customer_group_id/edit",
                  name: "product-must-haves-edit",
                  component: () =>
                    import("./views/master/product-must-haves/edit.vue"),
                },
                {
                  path: "import",
                  name: "product-must-haves-import",
                  component: () =>
                    import(
                      "./views/master/product-must-haves/import/index.vue"
                    ),
                },
              ],
            },
            {
              path: "product-flag",
              component: () => import("./views/master/index.vue"),
              children: [
                {
                  path: "",
                  name: "product-flag",
                  component: () =>
                    import("./views/master/product-flag/index.vue"),
                },
                {
                  path: "create",
                  name: "product-flag-create",
                  component: () =>
                    import("./views/master/product-flag/create.vue"),
                },
                {
                  path: ":territory_id/:customer_group_id/edit",
                  name: "product-flag-edit",
                  component: () =>
                    import("./views/master/product-flag/edit.vue"),
                },
                {
                  path: "import",
                  name: "product-flag-import",
                  component: () =>
                    import("./views/master/product-flag/import/index.vue"),
                },
              ],
            },
            {
              path: "product-back-to-back",
              component: () => import("./views/master"),
              children: [
                {
                  path: "",
                  name: "product-back-to-back",
                  component: () =>
                    import("./views/master/product-back-to-back/index.vue"),
                },
                {
                  path: "create",
                  name: "product-back-to-back-create",
                  component: () =>
                    import("./views/master/product-back-to-back/create.vue"),
                },
                {
                  path: ":id/edit",
                  name: "product-back-to-back-edit",
                  component: () =>
                    import("./views/master/product-back-to-back/edit.vue"),
                },
                {
                  path: "import",
                  name: "product-b2b-import",
                  component: () =>
                    import(
                      "./views/master/product-back-to-back/import/index.vue"
                    ),
                },
              ],
            },
            {
              path: "salesman-items",
              component: () => import("./views/master/index.vue"),
              children: [
                {
                  path: "",
                  name: "salesman-items",
                  component: () =>
                    import("./views/master/salesman-items/index.vue"),
                },
                {
                  path: "create",
                  name: "salesman-items-create",
                  component: () =>
                    import("./views/master/salesman-items/create.vue"),
                },
                {
                  path: ":territory_id/:customer_group_id/edit",
                  name: "salesman-items-edit",
                  component: () =>
                    import("./views/master/salesman-items/edit.vue"),
                },
              ],
            },

            // ==================== Start Adjustment Stock ====================
            {
              path: "adjustment-stock-item",
              name: "adjustment-stock-item",
              component: () =>
                import("./views/master/adjustment-stock-item/index.vue"),
            },
            // ==================== End Adjustment Stock ====================
            {
              path: "customer",
              component: () => import("./views/master/customer/index.vue"),
              children: [
                {
                  path: "",
                  name: "customer",
                  component: () => import("./views/master/customer/index.vue"),
                },
              ],
            },
            {
              path: "customers",
              component: () => import("./views/master/customer/index-all.vue"),
              children: [
                {
                  path: "",
                  name: "customers",
                  component: () =>
                    import("./views/master/customer/index-all.vue"),
                },
              ],
            },
            {
              path: "view-customers",
              component: () => import("./views/master/customer/only-view/index-all.vue"),
              children: [
                {
                  path: "",
                  name: "view-customers",
                  component: () =>
                    import("./views/master/customer/only-view/index-all.vue"),
                },
              ],
            },
            {
              path: "customers-address",
              component: () =>
                import("./views/master/customer-address/index-all.vue"),
              children: [
                {
                  path: ":id",
                  name: "customers-address",
                  component: () =>
                    import("./views/master/customer-address/index-all.vue"),
                },
              ],
            },
            {
              path: "view-customers-address",
              component: () =>
                import("./views/master/customer/only-view/address/index-all.vue"),
              children: [
                {
                  path: ":id",
                  name: "view-customers-address",
                  component: () =>
                    import("./views/master/customer/only-view/address/index-all.vue"),
                },
              ],
            },
            {
              path: "customer-address",
              component: () =>
                import("./views/master/customer-address/index.vue"),
              children: [
                {
                  path: ":id",
                  name: "customer-address",
                  component: () =>
                    import("./views/master/customer-address/index.vue"),
                },
              ],
            },
            {
              path: "customer-address-shipto",
              component: () =>
                import("./views/master/customer-address/index-shipto.vue"),
              children: [
                {
                  path: "",
                  name: "customer-address",
                  component: () =>
                    import("./views/master/customer-address/index-shipto.vue"),
                },
              ],
            },
            {
              path: "customer-update",
              component: () => import("./views/master/index.vue"),
              children: [
                {
                  path: "",
                  name: "customer-update",
                  component: () =>
                    import("./views/master/customer-update/index.vue"),
                },
                {
                  path: "create",
                  name: "customer-update-create",
                  component: () =>
                    import("./views/master/customer-update/Form.vue"),
                },
                {
                  path: ":id/edit",
                  name: "customer-update-edit",
                  component: () =>
                    import("./views/master/customer-update/Form.vue"),
                },
              ],
            },
            {
              path: "sales-target",
              component: () => import("./views/master/index.vue"),
              children: [
                {
                  path: "",
                  name: "sales-target",
                  component: () =>
                    import("./views/master/sales-target/index.vue"),
                },
                {
                  path: "create",
                  name: "sales-target-create",
                  component: () =>
                    import("./views/master/sales-target/Form.vue"),
                },
                {
                  path: ":id/edit",
                  name: "sales-target-edit",
                  component: () =>
                    import("./views/master/sales-target/Form.vue"),
                },
                {
                  path: ":id/view",
                  name: "sales-target-view",
                  component: () =>
                    import("./views/master/sales-target/View.vue"),
                },
                {
                  path: "import",
                  name: "sales-target-import",
                  component: () =>
                    import("./views/master/sales-target/import/index.vue"),
                },
              ],
            },
            {
              path: "work-team",
              component: () => import("./views/master/index.vue"),
              children: [
                {
                  path: "",
                  name: "work-team",
                  component: () => import("./views/master/work-team/index.vue"),
                },
                {
                  path: "create",
                  name: "work-team-create",
                  component: () => import("./views/master/work-team/form.vue"),
                },
                {
                  path: "update/:work_team_id/:company_id/edit",
                  name: "work-team-edit",
                  component: () => import("./views/master/work-team/form.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "setting",
          component: () => import("./views/setting"),
          children: [
            {
              path: "user",
              component: () => import("./views/setting/index.vue"),
              children: [
                {
                  path: "",
                  name: "user",
                  component: () => import("./views/setting/user/index.vue"),
                },
                {
                  path: "add",
                  name: "user-add",
                  component: () => import("./views/setting/user/form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "user-edit",
                  component: () => import("./views/setting/user/form.vue"),
                },
              ],
            },
            {
              path: "user-device",
              name: "user-device",
              component: () => import("./views/setting/user-device/index.vue"),
            },
            {
              path: "role",
              component: () => import("./views/setting/index.vue"),
              children: [
                {
                  path: "",
                  name: "role",
                  component: () => import("./views/setting/role/index.vue"),
                },
              ],
            },
            {
              path: "menu",
              component: () => import("./views/setting/index.vue"),
              children: [
                {
                  path: "",
                  name: "menu",
                  component: () => import("./views/setting/menu/index.vue"),
                },
              ],
            },
            {
              path: "role-menu",
              component: () => import("./views/setting/index.vue"),
              children: [
                {
                  path: "",
                  name: "role-menu",
                  component: () =>
                    import("./views/setting/role-menu/index.vue"),
                },
                {
                  path: "add",
                  name: "role-menu-add",
                  component: () => import("./views/setting/role-menu/form.vue"),
                },
                {
                  path: "edit/:id",
                  name: "role-menu-edit",
                  component: () => import("./views/setting/role-menu/form-v1.vue"),
                },
              ],
            },
            {
              path: "setting",
              component: () => import("./views/setting/index.vue"),
              children: [
                {
                  path: "",
                  name: "setting",
                  component: () => import("./views/setting/setting/index.vue"),
                },

                {
                  path: ":id/edit/:platform",
                  name: "setting-edit",
                  component: () => import("./views/setting/setting/Form.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "survey",
          component: () => import("./views/survey"),
          children: [
            {
              path: "survey-answer",
              component: () => import("./views/survey/index.vue"),
              children: [
                {
                  path: "",
                  name: "survey-answer",
                  component: () =>
                    import("./views/survey/survey-answer/index.vue"),
                },
                {
                  path: "create",
                  name: "survey-answer-create",
                  component: () =>
                    import("./views/survey/survey-answer/Form.vue"),
                },
                {
                  path: ":id/edit",
                  name: "survey-answer-edit",
                  component: () =>
                    import("./views/survey/survey-answer/Form.vue"),
                },
              ],
            },
            {
              path: "survey-catalog",
              component: () => import("./views/survey/index.vue"),
              children: [
                {
                  path: "",
                  name: "survey-catalog",
                  component: () =>
                    import("./views/survey/survey-catalog/index.vue"),
                },
                {
                  path: "create",
                  name: "survey-catalog-create",
                  component: () =>
                    import("./views/survey/survey-catalog/Form.vue"),
                },
                {
                  path: "view/:id",
                  name: "survey-catalog-view",
                  component: () =>
                    import("./views/survey/survey-catalog/View.vue"),
                },
                {
                  path: ":id/:type",
                  name: "survey-catalog-edit",
                  component: () =>
                    import("./views/survey/survey-catalog/Form.vue"),
                },
              ],
            },
            {
              path: "survey-allocation",
              component: () => import("./views/survey/index.vue"),
              children: [
                {
                  path: "",
                  name: "survey-allocation",
                  component: () =>
                    import("./views/survey/survey-allocation/index.vue"),
                },
                {
                  path: "create",
                  name: "survey-allocation-create",
                  component: () =>
                    import("./views/survey/survey-allocation/Form.vue"),
                },
                {
                  path: ":id/edit",
                  name: "survey-allocation-edit",
                  component: () =>
                    import("./views/survey/survey-allocation/Form.vue"),
                },
              ],
            },
            {
              path: "monitoring",
              component: () => import("./views/survey/index.vue"),
              children: [
                {
                  path: "",
                  name: "survey-monitoring",
                  component: () =>
                    import("./views/survey/monitoring/index.vue"),
                },
              ],
            },
            {
              path: "monitoring-detail",
              component: () => import("./views/survey/index.vue"),
              children: [
                {
                  path: "",
                  name: "survey-monitoring-detail",
                  component: () =>
                    import("./views/survey/monitoring/DataTableDetail.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "/sales-order",
          name: "sales-order",
          component: () => import("./views/customer-order/index.vue"),
        },
        {
          path: "/sales-order/edit/:id",
          name: "sales-order-edit",
          component: () => import("./views/customer-order/form_v2/edit.vue"),
          // component: () => import("./views/customer-order/form/edit.vue"),
        },
        // Persiapan route after create CO, langsung redirect ke view
        {
          path: "/sales-order/view/:id",
          name: "sales-order-view",
          component: () => import("./views/customer-order/form_v2/view.vue"),
        },
        {
          path: "/sales-order/:id",
          name: "sales-order-detail",
          component: () => import("./views/customer-order/detail.vue"),
        },
        {
          //v1
          path: "/customer-order-create_v1",
          name: "customer-order-create_v1",
          component: () => import("./views/customer-order/form/create.vue"),
        },
        {
          // v2
          path: "/sales-order-create",
          name: "sales-order-create",
          component: () => import("./views/customer-order/form_v2/create.vue"),
        },
        // =======Payment=======
        {
          name: "payment",
          path: "/payment",
          component: () => import("./views/payment/index.vue"),
        },
        {
          name: "payment-detail",
          path: "/payment/:id/detail",
          component: () => import("./views/payment/detail.vue"),
        },
        {
          path: "approval",
          component: () => import("./views/approval"),
          children: [
            {
              path: "customer",
              component: () => import("./views/approval/customer/index.vue"),
              children: [
                {
                  path: "",
                  name: "customer",
                  component: () =>
                    import("./views/approval/customer/index.vue"),
                },
              ],
            },
            {
              path: "customer-address",
              component: () =>
                import("./views/approval/customer-address/index.vue"),
              children: [
                {
                  path: ":id",
                  name: "customer-address",
                  component: () =>
                    import("./views/approval/customer-address/index.vue"),
                },
              ],
            },
            {
              path: "customer-address-shipto",
              component: () =>
                import("./views/approval/customer-address-shipto/index.vue"),
              children: [
                {
                  path: "",
                  name: "customer-address-shipto",
                  component: () =>
                    import(
                      "./views/approval/customer-address-shipto/index.vue"
                    ),
                },
              ],
            },
            {
              path: "/approval/collection-plan-approval",
              component: () =>
                import("./views/approval/collection-plan-approval/index.vue"),
            },
            {
              path: "/approval/collection-plan-approval/:id/show",
              name: "collection-plan-show-approval",
              component: () =>
                import("./views/approval/collection-plan-approval/detail.vue"),
            },
            {
              path: "/approval/manage-collection-change-approval",
              name: "manage-collection-change-approval",
              component: () =>
                import("./views/approval/collection-plan-manage/index.vue"),
            },
            {
              path: "collection-plan-manage/:id/show",
              name: "collection-plan-manage-show",
              component: () =>
                import("./views/approval/collection-plan-manage/show.vue"),
            },
            {
              path: "sales-target",
              component: () => import("./views/approval/index.vue"),
              children: [
                {
                  path: "",
                  name: "approval-sales-target",
                  component: () =>
                    import("./views/approval/sales-target/index.vue"),
                },
                {
                  path: ":id/view",
                  name: "approval-sales-target-view",
                  component: () =>
                    import("./views/approval/sales-target/View.vue"),
                },
              ],
            },
          ],
        },
        // =======Sync Master=======
        {
          path: "/sync-master",
          name: "sync-master",
          component: () => import("./views/sync-master/index.vue"),
          children: [
            {
              path: "",
              name: "sync-master",
              component: () => import("./views/sync-master/index.vue"),
            },
            {
              path: "customer-address-shipto",
              component: () =>
                import("./views/approval/customer-address-shipto/index.vue"),
              children: [
                {
                  path: "",
                  name: "customer-address-shipto",
                  component: () =>
                    import(
                      "./views/approval/customer-address-shipto/index.vue"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "report",
          component: () => import("./views/report"),
          children: [
            {
              path: "noo",
              name: "report-noo",
              component: () => import("./views/report/noo/index.vue"),
            },
            {
              path: "customer-survey",
              name: "report-customer-survey",
              component: () =>
                import("./views/report/customer-survey/index.vue"),
            },
            {
              path: "stock-outlet",
              name: "report-stock-outlet",
              component: () => import("./views/report/stock-outlet/index.vue"),
            },
            {
              path: "collection-ar-aging",
              name: "report-collection-ar-aging",
              component: () =>
                import("./views/report/collection-ar-aging/index.vue"),
            },
            {
              path: "summary-customer-visit",
              name: "report-summary-customer-visit",
              component: () =>
                import("./views/report/summary-customer-visit/index.vue"),
            },
          ],
        },
        // =======Collection Plan route=======
        {
          path: "/collection-plan-route",
          component: () =>
            import("./views/collection-plan/collection-plan-route/index.vue"),
        },
        {
          path: "/collection-plan-route/:id/show",
          name: "collection-plan-show",
          component: () =>
            import("./views/collection-plan/collection-plan-route/detail.vue"),
        },
        {
          path: "/collection-plan-route/new/on-route",
          name: "collection-plan-new-on-route",
          component: () =>
            import(
              "./views/collection-plan/collection-plan-route/on-route/indexv2.vue"
            ),
        },
        {
          path: "/collection-plan-route/new/off-route",
          name: "collection-plan-new-off-route",
          component: () =>
            import(
              "./views/collection-plan/collection-plan-route/off-route/indexv2.vue"
            ),
        },

        // =======Manage Collection Plan=======
        {
          path: "/collection-plan-manage",
          name: "collection-plan-manage",
          component: () =>
            import("./views/collection-plan/collection-plan-manage/index.vue"),
        },
        {
          path: "/collection-plan-manage/:id/edit",
          name: "collection-plan-manage-edit",
          component: () =>
            import(
              "./views/collection-plan/collection-plan-manage/edit-v2.vue"
            ),
        },
        {
          path: "/collection-plan-manage/:id/showv2",
          name: "collection-plan-manage-showv2",
          component: () =>
            import("./views/collection-plan/collection-plan-manage/showv2.vue"),
        },
        {
          path: "/collection-plan-manage/:id/show-wait-approval",
          name: "collection-plan-manage-show-wait-approval",
          component: () =>
            import(
              "./views/collection-plan/collection-plan-manage/show-wait-approval.vue"
            ),
        },
        {
          path: "/collection-plan-manage/:id/show",
          name: "collection-plan-manage-show-cpm",
          component: () =>
            import("./views/collection-plan/collection-plan-manage/show.vue"),
        },

        // =======Monitoring Dashboard Sales Visit=======
        {
          path: "/monitoring-dashboard-sfa",
          name: "monitoring-dashboard-sfa",
          component: () =>
            import("./views/monitoring-dashboard/sales-visit/index.vue"),
        },
        {
          path: "/monitoring-dashboard-sfa/show",
          name: "monitoring-dashboard-sfa-show",
          component: () =>
            import(
              "./views/monitoring-dashboard/sales-visit/detail-v2/index.vue"
            ),
        },
        {
          path: "/monitoring-dashboard-sfa/show-billing/:id_bill",
          name: "monitoring-dashboard-sfa-show-billing",
          component: () =>
            import(
              "./views/monitoring-dashboard/sales-visit/detail/billing.vue"
            ),
        },
        // =======Monitoring Dashboard ETL=======
        {
          path: "/monitoring-dashboard-etl",
          name: "monitoring-dashboard-etl",
          component: () => import("./views/monitoring-dashboard/etl/index.vue"),
        },
        {
          path: "import",
          component: () => import("./views/import/index.vue"),
          children: [
            {
              path: "customer",
              name: "customer",
              component: () => import("./views/import/customer/index.vue"),
            },
          ],
        },
        // =======Manage Canvas Sales=======
        {
          path: "/manage-canvas-sales",
          name: "manage-canvas-sales",
          component: () => import("./views/manage-canvas-sales/index.vue"),
        },
        {
          path: "/manage-canvas-sales/view/:id",
          name: "mgcanvas-sales-view",
          component: () => import("./views/manage-canvas-sales/view.vue"),
        },
        {
          path: "/manage-canvas-sales/view-so/:id",
          name: "mgcanvas-sales-view-so",
          component: () => import("./views/manage-canvas-sales/view_so.vue"),
        },
        {
          path: "/manage-canvas-sales/print-so/:id",
          name: "mgcanvas-sales-print-so",
          component: () => import("./views/manage-canvas-sales/print_so.vue"),
        },
        {
          path: "/manage-canvas-sales/print-inv-so/:inv_code",
          name: "mgcanvas-sales-print-inv",
          component: () => import("./views/manage-canvas-sales/inv_print/invoice-print.vue"),
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/Login.vue"),
        },
        {
          path: "/pages/change-log",
          name: "page-change-log",
          component: () => import("@/views/pages/ChangeLog.vue"),
        },
        {
          path: "/pages/forgot-password",
          name: "forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
        },
        {
          path: "/pages/reset-password/:id",
          name: "reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
        },
        {
          path: "/term-of-service",
          name: "page-term-of-service",
          component: () => import("@/views/pages/TermOfService.vue"),
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
        },
        {
          path: "/version",
          name: "version",
          component: () => import("@/views/version.vue"),
        },
        {
          path: "/auth/callback",
          name: "auth-callback",
          component: () => import("@/views/auth/callback.vue"),
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
    {
      path: "/collection-plan-manage/print/pdf",
      name: "collection-plan-manage-print-pdf",
      component: () =>
        import("./views/collection-plan/collection-plan-manage/print-pdf.vue"),
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
router.beforeEach(routerGuard);
export default router;
