<template>
  <vx-card title="Route Plan">
    <div class="vx-input-group flex">
      <vs-button
        v-if="this.$store.getters['user/hasPermissions']('create')"
        class="mt-2 mr-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
      <vs-button
        class="mt-2 mr-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-upload"
        @click="handleImport()"
        >Import</vs-button
      >
      <vs-button
        class="mt-2 mr-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-download"
        @click="handleExport()"
        >Export</vs-button
      >
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        Selected Route Plan to Export:
        {{ this.selectedRoutePlanToExportCodes.join(", ") }}
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLableTerritory"
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Zone</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedZone"
          :options="optionZone"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLableTerritory"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-tabs v-model="activeTab">
          <vs-tab label="Active">
            <div class="tab-text">
              <data-table
                :territoryID="
                  this.selectedTerritory ? this.selectedTerritory.id : null
                "
                :zoneID="this.selectedZone ? this.selectedZone.id : null"
                :baseUrl="this.baseUrl"
                :detail="this.detail"
                status="Active"
                @selectedRoutePlanToExport="setSelectedRoutePlanToExport"
              ></data-table>
            </div>
          </vs-tab>
          <vs-tab label="Inactive">
            <div class="tab-text">
              <data-table
                :territoryID="
                  this.selectedTerritory ? this.selectedTerritory.id : null
                "
                :zoneID="this.selectedZone ? this.selectedZone.id : null"
                :baseUrl="this.baseUrl"
                :detail="this.detail"
                status="Inactive"
                @selectedRoutePlanToExport="setSelectedRoutePlanToExport"
              ></data-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    DataTable,
    Datepicker,
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/sfa/v1/route-plan",
      optionTerritory: [],
      selectedTerritory: null,
      optionZone: [],
      selectedZone: null,
      selectedRoutePlanToExport: [],
      selectedRoutePlanToExportIDs: [],
      selectedRoutePlanToExportCodes: [],
      activeTab: 0,
    };
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "route-plan-create",
        // params: { id: id },
      });
    },
    handleImport() {
      this.$router.push({
        name: "route-plan-import",
        // params: { id: id },
      });
    },

    handleExport() {
      if (this.selectedRoutePlanToExportIDs.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select route plan to export",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      // this.$vs.loading();
      // var fileTitle =
      //   "ROUTE_PLAN_EXPORT_" + this.selectedRoutePlanToExportCodes.join("_");
      var fileTitle = "ROUTE_PLAN_EXPORT";

      // if (this.selectedTerritory) {
      //   fileTitle +=
      //     "_" + this.selectedTerritory.code + "_" + this.selectedTerritory.name;
      // }

      fileTitle += "_" + moment().format("YYYYMMDDHHmmss");

      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            route_plan_ids: this.selectedRoutePlanToExportIDs,
          },
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },

    setSelectedRoutePlanToExport(val) {
      var routePlanIDs = [];
      var routePlanCodes = [];
      val.forEach((element) => {
        routePlanIDs.push(element.id);
        routePlanCodes.push(element.code);
      });

      this.selectedRoutePlanToExportIDs = routePlanIDs;
      this.selectedRoutePlanToExportCodes = routePlanCodes;
    },
    handleOpen() {
      this.detail = true;
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = null;
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = null;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getZone() {
      if (this.selectedTerritory.code) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/zone", {
            params: {
              length: 0,
              order: "name",
              sort: "asc",
              territory_code: this.selectedTerritory.code,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (resp.data.records) {
                this.optionZone = resp.data.records;
                if (this.optionZone.length > 0) {
                  this.selectedZone = this.optionZone[0];
                } else {
                  this.optionZone = [];
                  this.selectedZone = null;
                }
              } else {
                this.optionZone = [];
                this.selectedZone = null;
              }
              this.$vs.loading.close();
            } else {
              this.optionZone = [];
              this.selectedZone = {};
              this.$vs.loading.close();
            }
          });
      } else {
        this.optionZone = [];
        this.selectedZone = null;
      }
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "master-route-plan");
    this.getTerritory();
  },
  watch: {
    selectedTerritory() {
      this.getZone();
    },
    activeTab() {
      this.selectedRoutePlanToExport = [];
      this.selectedRoutePlanToExportIDs = [];
      this.selectedRoutePlanToExportCodes = [];
    },
  },
  computed: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
